<template>
    <NuxtLink v-slot="{href, navigate}" :to="collection.url!" custom>
        <div class="collection-thumbnail" @click="navigate">
            <div>
                <div class="heading">{{ t('resources.collection') }}</div>
                <a :href="href">
                    <h3 v-if="collection.data?.title" class="collection-title">
                        {{ collection.data.title }}
                    </h3>
                </a>
            </div>
            <div>
                <div v-for="[contentType, amount] in Object.entries(collectionNumbers).filter(([_, amount]) => amount > 0)" :key="contentType">
                    <span class="number">{{ amount }}</span>
                    <span>{{ t(`resources.${amount > 1 ? customTypePlurals[contentType] : contentType}`) }}</span>
                </div>
            </div>
        </div>
    </NuxtLink>
</template>
<script lang="ts" setup>
    import {useTranslation, useContentHelpers} from '#imports';
    import type {CollectionDocument, CollectionDocumentData} from '@prismicio-types';
    import type {FilledContentRelationshipField} from '@prismicio/types';

    const props = defineProps<{collection: CollectionDocument | FilledContentRelationshipField<'collection', string, CollectionDocumentData>}>();
    const {t} = useTranslation();
    const {getCollectionNumbers} = useContentHelpers();
    const collectionNumbers = getCollectionNumbers(props.collection);
    const customTypePlurals: Record<string, string> = {
        article: 'articles',
        successStory: 'successStories',
        guide: 'guides',
        event: 'events',
    };
</script>

<style lang="scss">
    @use '~/assets/styles/variables' as *;
    .collection-thumbnail {
        background-color: var(--joy-color-primary-70);
        padding: var(--joy-core-spacing-6);
        border-radius: var(--joy-core-radius-3);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        background-image: url('~/assets/svg/cornerPill.svg');
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: 70%;
        transition: background-size var(--joy-transition-duration-default) var(--joy-transition-timing-function);

        * {
            color: white;
        }
        &:hover {
            background-size: 140%;
        }
        .heading {
            margin-bottom: var(--joy-core-spacing-4);
            font-size: var(--joy-font-size-primary-800);
            font-family: var(--joy-font-family-title);
            @media (max-width: $mobile-breakpoint) {
                font-size: var(--joy-font-size-primary-600);
            }
        }
        .collection-title {
            margin-bottom: var(--joy-core-spacing-12);
            @media (max-width: $mobile-breakpoint) {
                margin-bottom: var(--joy-core-spacing-7);
            }
        }
        .number {
            font-size: var(--joy-font-size-primary-800);
            margin-right: var(--joy-core-spacing-2);
        }
    }
</style>
